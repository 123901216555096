<template>
	<div
		style="width: 200px; float: left; height: 100vh; border-right: 1px solid #eee; background-color: #25d8ae; color: #fff;">
		<div style="height: 60px;">
			<div style="width: 60px; float: left;">
				<img :src="'./images/logo.jpg'" style="width: 100%; vertical-align: middle;">
			</div>
			<div style="margin-left: 60px; height: 100%; overflow: hidden;" class="flexcenter">
				<div style="padding: 0 5px;">
					{{$store.state.shopInfo.shop_name}}
				</div>
			</div>


		</div>

		<div style="padding-top:50px ;">

			<div :class="['menuitem',ckdmenu=='/orders'?'mckd':'']" @click="goPage('/orders')">订单列表</div>
			<div :class="['menuitem',ckdmenu=='/printers'?'mckd':'']" @click="goPage('/printers')">打印机</div>
			<div :class="['menuitem',ckdmenu=='/mydata'?'mckd':'']" @click="goPage('/mydata')">我的数据</div>
			<div :class="['menuitem',ckdmenu=='/shop'?'mckd':'']" @click="goPage('/shop')">店铺设置</div>

		</div>

	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				ckdmenu: ''
			}
		},
		mounted() {
			this.ckdmenu=this.$route.path
		},
		methods: {
			goPage(url) {
				if(this.$route.path==url){
					
				}else{
					this.ckdmenu=url
					this.$router.push(url)
				}
			}

		},
	}
</script>

<style scoped>
	.menuitem {
		width: 80%;
		padding: 10px 0;
		text-align: center;
		border: 1px solid #fff;
		margin: 0 auto;
		border-radius: 4px;
		margin-top: 10px;
		cursor: pointer;
	}

	.mckd {
		background-color: #fff;
		color: #25d8ae;
	}
</style>
