<template>

	<div style="width: 100%;height: 100vh">
		<leftmenu></leftmenu>
		<div style="margin-left: 201px; height: 100vh; position: relative;">
			<myheader></myheader>
			<div style="height: 100%; box-sizing: border-box;  border-top: 61px solid #fff;">
				<router-view></router-view>
			</div>
		</div>
		
		
		
	</div>

</template>

<script>
	import myheader from './header.vue'
	import leftmenu from './leftmenu.vue'
	import rightcontent from './rightcontenet.vue'
	export default {
		components: {
		myheader,leftmenu,rightcontent
		},
		data() {
			return {
				
			}
		},
		mounted() {
			this.getShop()
		},
		methods: {
			getShop(){
				this.$http.post("/api/get_shop_info").then(res=>{
					this.$store.dispatch("setShopInfo",res.data)
				})
			}
   
		},
	}
</script>
