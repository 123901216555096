<template>
	<div class="header">
		<div style="line-height: 60px; font-size: 18px; padding: 0 10px; float: left;">
			注册码：{{ $store.state.shopInfo.shop_code }}
		</div>
		<div style=" float: left;line-height: 60px; font-size: 18px; padding: 0 10px;">
			打印码：

			<el-image v-if="$store.state.shopInfo.qrcode_print" class="qrcode" :src="$store.state.shopInfo.qrcode_print"
				:preview-src-list="[$store.state.shopInfo.qrcode_print]">
			</el-image>

			<el-button v-else size="mini" @click="createPrintQrcode">生成</el-button>
		</div>
		<div style=" float: left;line-height: 60px; font-size: 18px; padding: 0 10px;">
			家庭码：
			<el-image v-if="$store.state.shopInfo.qrcode_family" class="qrcode" :src="$store.state.shopInfo.qrcode_print"
				:preview-src-list="[$store.state.shopInfo.qrcode_family]">
			</el-image>
			
			
			<el-button v-else size="mini" @click="createFamilyQrcode">生成</el-button>
		</div>

		<div style=" float: left;line-height: 60px; font-size: 18px; padding: 0 10px;">
			<a href="./images/blwy.zip" target="_blank" class="mya"> <i class="el-icon-download"></i> 点击下载打印机程序</a>
		</div>



	</div>
</template>

<script>

export default {

	data() {
		return {
			taped: false
		}
	},
	mounted() {

	},
	methods: {
		getShop() {
			this.$http.post("/api/get_shop_info").then(res => {
				this.$store.dispatch("setShopInfo", res.data)
			})
		},
		createPrintQrcode() {
			if (!this.taped) {
				this.taped = true
			} else {
				return
			}
			this.$http.post("/api/create_shop_qrcode").then(res => {
				this.getShop()
				this.taped = false
			})


		},
		createFamilyQrcode() {
			if (!this.taped) {
				this.taped = true
			} else {
				return
			}
			this.$http.post("/api/create_family_qrcode").then(res => {
				this.getShop()
				this.taped = false
			})


		},
	},
}
</script>


<style>
.header {
	height: 60px;
	width: 100%;
	border-bottom: 1px solid #eee;
	position: absolute;
	top: 0;
}

.qrcode {
	width: 40px;
	height: 40px;
	vertical-align: middle;
	transform: translateY(-2px);
	cursor: pointer;
}
.mya{ text-decoration: none; font-size: 14px; color: blue;}

</style>
